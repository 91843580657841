import { useIsImgLoaded } from '#app/hooks/useIsImgLoaded';

type ImgProps = {
  alt: string;
  className?: string;
  lazy?: boolean;
  src: string;
};

export default function Image(props: ImgProps) {
  const { alt, className, lazy, src } = props;

  const { elementRef, isLoaded } = useIsImgLoaded(lazy ?? false);

  return (
    <img
      alt={alt}
      className={className}
      ref={elementRef.current}
      src={src}
    />
  );
}